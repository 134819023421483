
import moment from "moment";
import SearchSelect from "../search-select";

export default {
    name: "FormulateInputAutocomplete",
    components: {SearchSelect},
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            updated: moment().valueOf()
        }
    },
    computed: {
        placeholder() {
            return this.context.attributes['select-placeholder'] ? this.context.attributes['select-placeholder'] : "Search...";
        },
        searchable() {
            return this.context.attributes['select-searchable'] ? this.context.attributes['select-searchable'] : true;
        },
        label() {
            return this.context.attributes['select-label'] ? this.context.attributes['select-label'] : "label";
        },
        selectedLabel() {
            return this.context.attributes['select-selected-label'] ? this.context.attributes['select-selected-label'] : null;
        },
        optionsTitle() {
            return this.context.attributes['select-options-title'] ? this.context.attributes['select-options-title'] : "option";
        },
        params() {
            return this.context.attributes['select-params'] ? this.context.attributes['select-params'] : {};
        },
        neededInput() {
            if (
                this.context.attributes['select-needed-input'] === 0 ||
                this.context.attributes['select-needed-input'] === "0"
            ) {
                return 0;
            } else {
                return this.context.attributes['select-needed-input'] ? this.context.attributes['select-needed-input'] : 3;
            }
        },
        handleInput() {
            return this.context.attributes['select-handle-input'] ? this.context.attributes['select-handle-input'] : null;
        },
        debounceTime() {
            return this.context.attributes['debounce-time'] ? this.context.attributes['debounce-time'] : 750;
        },
        clearable() {
            return this.context.attributes['select-clearable'] === false ?
                !(!this.context.attributes['select-clearable'] && this.context.attributes['select-multiple']) :
                true
        },
    },
    watch: {
        "context.model": {
            handler() {
                this.$nextTick(()=>{
                    // this.updated = moment().valueOf();
                })

            }
        }
    },
    methods: {
        onOpen() {
            this.context.rootEmit('open');
        },
        onClose() {
            this.context.rootEmit('close');
        },
    }
}
