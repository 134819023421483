export default {
    data() {
        return {
            modals: []
        }
    },
    methods: {
        openModal(ref, options = {}) {
            const myModalEl = ref.$el;
            const modal = new this.$bootstrap.Modal(myModalEl, options)
            modal.show();
            console.log("Showing modal", modal);
            this.modals.push(modal);
            return modal;
        },
        closeModal(modal) {
            console.log("Closing modal by instance", modal);
            modal.hide();
            this.modals = this.modals.filter(m => m !== modal);
        },
        closeModalById(id) {
            const myModalEl = document.getElementById(id);
            const modal = this.$bootstrap.Modal.getInstance(myModalEl);
            console.log("Closing modal by id " + id, modal);
            modal.hide();
        }
    },
    beforeDestroy() {
        // console.log("This is...", this);
        // console.log("Modals are...", this.modals);
        this.modals.map(m => m.hide());
        this.modals = [];
    }
}
