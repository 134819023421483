
export default {
    name: "QuintableSearchInput",
    props: {
        value: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        }
    },
    methods: {
        emitInput(e) {
            this.$emit('input', e);
        }
    }
}
