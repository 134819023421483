
import moment from "moment";
import TableFilters from "./table-filters";
import QuintableSearchInput from "./helpers/quintable-search-input.vue";
import LoadingSpinner from "./loading-spinner";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "NotificationsModal",
    components: {LoadingSpinner, QuintableSearchInput, TableFilters},
    mixins: [optionButtonTooltip],
    props: {
        id: {
            type: String,
            required: false,
            default: "notificationsModal"
        },
        userId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showNotifications: false,
            config: {
                columns: [
                    {
                        headline: "Title",
                        sort: true,
                        hideHeadlineBreakpoint: "all",
                    }, {
                        headline: "Body",
                        breakpoint: "all",
                        hideHeadlineBreakpoint: "all",
                    }, {
                        headline: "Time",
                        sort: true,
                        hideHeadlineBreakpoint: "all",
                    },
                ],
                pagination: 10,
                search: true,
                ajaxUrl: '/api/users/notifications',
            },
            onlyUnreadNotifications: false,
            updated: moment().valueOf(),
            loading: false,
            notificationLoaded: false,
        }
    },
    computed: {
        filters() {
            return {
                userId: this.userId,
                onlyUnreadNotifications: this.onlyUnreadNotifications,
            }
        }
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.showNotifications = true;
        });

        this.$refs.modal.addEventListener('hide.bs.modal', () => {
            this.showNotifications = false;
            this.notificationLoaded = false;
            this.$emit('closed');
        });
    },
    methods: {
        onRowsUpdated(data) {
            this.notificationLoaded = true;
            let smallestId = Number.MAX_SAFE_INTEGER;
            let highestId = 0;
            for (const row of data.rows) {
                const id = row.cells[0].id;
                if (id < smallestId) {
                    smallestId = id;
                }
                if (id > highestId) {
                    highestId = id;
                }
            }
            this.$emit('rows-updated', smallestId, highestId);
        },
        markAllAsRead() {
            this.initializeOptionButtonTooltips();
            this.loading = true;
            this.$axios.post("/api/users/mark_notifications_as_read", {
                smallestId: null,
                highestId: null,
            }).then(() => {
                this.updated = moment().valueOf();
            }).finally(() => {
                this.loading = false;
            })
        }
    },
}
