export default {
    methods: {
        isOpera() {
            // eslint-disable-next-line no-undef
            return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.includes(' OPR/');
        },
        isFirefox() {
            return typeof InstallTrigger !== 'undefined';
        },
        isSafari() {
            // eslint-disable-next-line max-len
            return /constructor/i.test(window.HTMLElement) || (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification));
        },
        isIE() {
            // eslint.disable
            return /* @cc_on!@ */false || !!document.documentMode;
            // eslint.enable
        },
        isChrome() {
            return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        },
        isEdgeChromium() {
            return this.isChrome() && (navigator.userAgent.includes("Edg"));
        },
        isBlink() {
            return (this.isChrome() || this.isOpera()) && !!window.CSS;
        }
    }
}
