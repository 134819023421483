import Vue from "vue";
import FormulateInputAutocomplete from "../components/formulate/FormulateInputAutocomplete";
import FormulateInputEditor from "../components/formulate/FormulateInputEditor";
import FormulateInputPrettyCheckbox from "../components/formulate/FormulateInputPrettyCheckbox";
import FormulateInputPrettyRadios from "../components/formulate/FormulateInputPrettyRadios";
import FormulateInputSearchSelect from "../components/formulate/FormulateInputSearchSelect";
import FormulateInputTextarea from "../components/formulate/FormulateInputTextarea";
import FormulateInputDatetime from "../components/formulate/FormulateInputDatetime.vue";
import FormulateInputPrettyRadioGroups from "../components/formulate/FormulateInputPrettyRadioGroups.vue";


Vue.component('FormulateInputAutocomplete', FormulateInputAutocomplete);
Vue.component('FormulateInputEditor', FormulateInputEditor);
Vue.component('FormulateInputPrettyCheckbox', FormulateInputPrettyCheckbox);
Vue.component('FormulateInputPrettyRadios', FormulateInputPrettyRadios);
Vue.component('FormulateInputSearchSelect', FormulateInputSearchSelect);
Vue.component('FormulateInputTextarea', FormulateInputTextarea);
Vue.component('FormulateInputDatetime', FormulateInputDatetime);
Vue.component('FormulateInputPrettyRadioGroups', FormulateInputPrettyRadioGroups);
