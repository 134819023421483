
import {mapGetters} from "vuex";
import SaveButton from "./save-button";

export default {
    name: "EditFavoritePageModal",
    components: {SaveButton},
    props: {
        page: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            title: "",
            url: "",
            hash: "",
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            currentRoute: "app/currentRoute"
        }),
        isNew() {
            return !this.hash
        },
    },
    watch: {
        page: {
            handler() {
                this.setAttributes();
            },
            immediate: true,
        },
        currentRoute: {
            handler() {
                this.setAttributes();
            },
            deep: true,
        }
    },

    methods: {
        setAttributes() {
            this.title = this.page ? this.page.title : ""
            this.url = this.page ? this.page.url : this.currentRoute
            this.hash = this.page ? this.page.hash : window.location.hash
        },
        save() {
            this.loading = true;

            this.$axios.post("/api/save_favorite_page", {
                url: this.url,
                title: this.title,
                hash: this.hash,
            }).then((response) => {
                this.$emit('pages-updated');

                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Saved",
                    message: response.data.message,
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            }).finally(() => {
                this.loading = false;
            })
        }
    }
}
