export default ({app, store}) => {
    // Every time the route changes (fired on initialization too)
    app.router.beforeEach( async (to, from, next) => {
        // do something to validate

        if (store.getters['app/warnNotSaved'] && !confirm('Are you sure you want to leave? Changes will get lost.')){
            next(false)
        } else {
            // Navigate to next view
            await app.store.dispatch('app/setWarnNotSaved', false);
            next()
        }
    })
}
