
import {mapActions} from "vuex";

export default {
    name: "Footer",
    components: {},
    data() {
        return {
            savePageModal: null,
            savePageKey: this.generateUUID(),
        }
    },
    methods: {
        ...mapActions({
            setUserData: "app/setUserData",
            setPreviousRoute: "app/setPreviousRoute",
        }),

    }
}
