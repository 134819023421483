export default ({app, store}) => {
    setInterval(() => {
        if (app.$auth.loggedIn) {
            const now = Math.floor(Date.now() / 1000); // Timestamp in s

            const lastKeepAlive = parseInt(localStorage.getItem('lastKeepAliveTimestamp'));
            const delta = now - lastKeepAlive;

            if (delta > 5 * 60) {
                // console.log("Updating KeepAlive after " + delta + "s");
                localStorage.setItem('lastKeepAliveTimestamp', now.toString());
                app.$axios.get("/keep_alive").then(response => {
                    store.dispatch("app/setNumberNotifications", response.data.numberNotifications);
                });
            } else {
                // console.log("Not updating KeepAlive because " + delta + "s < 5s");
            }
        }
    }, 5000 + Math.floor(Math.random() * 500));
}
