import vueInViewportMixin from "vue-in-viewport-mixin/index";


export default {
    mixins: [vueInViewportMixin],
    data() {
        return {
            collapsibleNavbar: null,
            isNavbarOpen: false,
        }
    },
    methods: {
        hiddenBsCollapseEventHandler() {
            this.isNavbarOpen = false;
            console.log("Navbar open?", this.isNavbarOpen);
        },
        showBsCollapseEventListener() {
            this.isNavbarOpen = true;
            console.log("Navbar open?", this.isNavbarOpen);
        },
    },
    mounted() {
        this.collapsibleNavbar = document.getElementById('collapsable-navbar')
        this.collapsibleNavbar.addEventListener('hidden.bs.collapse', this.hiddenBsCollapseEventHandler);
        this.collapsibleNavbar.addEventListener('show.bs.collapse', this.showBsCollapseEventListener);
    },
    destroyed() {
        this.collapsibleNavbar.removeEventListener('hidden.bs.collapse', this.hiddenBsCollapseEventHandler);
        this.collapsibleNavbar.removeEventListener('show.bs.collapse', this.showBsCollapseEventListener);
        this.collapsibleNavbar = null;
    }
}
