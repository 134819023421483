
import SaveButton from "./save-button";

export default {
    name: "GiveFeedbackModal",
    components: {SaveButton},
    props: {
        id: {
            type: String,
            default: () => "giveFeedbackModal"
        }
    },
    data() {
        return {
            loading: false,
            feedback: "",
        };
    },
    methods: {
        submitForm() {
            this.loading = true;
            this.$axios.post("/api/give_feedback", {
                "feedback": this.feedback,
                "full_path": this.$route.fullPath
            }).then((response => {
                this.$emit('feedback-sent');

                const toastId = this.generateUUID();
                if (response.data.status) {
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "Your feedback was sent successfully.",
                        id: toastId,
                    })
                } else {
                    this.addToast({
                        type: "danger",
                        title: "Error",
                        message: "There was an error while sending your feedback. Your feedback could not be sent.",
                        id: toastId,
                    })
                }
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            })).finally(() => {
                this.loading = false;
            })
        }
    }
}
