export default {
    data(){
        return {
            optionButtonTooltipsStore:[],
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initializeOptionButtonTooltips();
        })
    },
    methods: {
        initializeOptionButtonTooltips(referer = "unknown") {
            this.resetOptionButtonTooltips();
            // if(referer === "staffing"){
            //     console.log(this.$refs);
            // }
            Object.keys(this.$refs).forEach((key) => {
                if (key.startsWith("option-button")) {
                        let refs = this.$refs[key];
                        if (!Array.isArray(refs)) {
                            refs = [refs];
                        }
                        refs.forEach((ref) => {
                            try {

                                const element = ref && ref.$el ? ref.$el : ref;
                                if(!element || !element.getAttribute("data-bs-title")){
                                    return;
                                }
                                const tip = new this.$bootstrap.Tooltip(element, {
                                    template: "<div class=\"tooltip\" role=\"tooltip\"><div class=\"tooltip-inner\"></div></div>",
                                    delay:{ "show": 1000, "hide": 0 }
                                });
                                this.optionButtonTooltipsStore.push(tip)
                            } catch (e) {
                                console.log("Error:", e);
                            }
                        })


                }
            })
        },
        resetOptionButtonTooltips(){
            this.optionButtonTooltipsStore.forEach((tip)=>{
                tip.dispose();
            })
            this.optionButtonTooltipsStore = [];
        }
    },
    beforeDestroy() {
        this.resetOptionButtonTooltips();
    }
}
