
import LoadingSpinner from "./loading-spinner";

export default {
    name: "SaveButton",
    components: {LoadingSpinner},
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "submit",
            validator: function validator(value) {
                return ["submit", "button"].includes(value);
            }
        },
        label: {
            type: String,
            default: "Save",
        },
        color: {
            type: String,
            default: "info",
            validator: function validator(value) {
                return ["primary", "secondary", "success", "info", "warning", "danger", "outline-info"].includes(value);
            }
        },
    },
    computed: {
        btnDisabled() {
            return this.disabled || this.loading;
        }
    }
}
