
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Toast",
    props: {
        type: {
            type: String,
            default: "success",
            validator(value) {
                // The value must match one of these strings
                return ['success', 'warning', 'danger', 'info'].includes(value)
            }
        },
        uuid: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: "Shoot!"
        },
        message: {
            type: String,
            default: null
        },
        htmlMessage: {
            type: String,
            default: null
        },
        delay: {
            type: Number,
            default: 4000,
        }
    },
    computed: {
        ...mapGetters({
            showToast: "app/showToast",
        }),
        icon() {
            switch (this.type) {
                case "success":
                    return "check"
                case "warning":
                    return "exclamation"
                case "danger":
                    return "times"
                case "info":
                    return "info"
                default:
                    return "question"
            }
        }
    },
    watch: {
        showToast(showToast) {
            const show = showToast[this.uuid]
            if (show) {

                const toast = new this.$bootstrap.Toast(this.$refs[this.uuid], {
                    delay: this.delay
                }) // No need for options; use the default options
                toast.show();
                this.$nextTick(() => {
                    this.toggleToast(this.uuid);
                })
            }

        },
    },
    methods: {
        ...mapActions({
            toggleToast: "app/toggleToast"
        })
    }
}
