
export default {
    name: "FormulateInputPrettyCheckbox",
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            tooltip: null,
        }
    },
    computed: {
        type() {
            return this.context.attributes['checkbox-type'] ? this.context.attributes['checkbox-type'] : 'info';
        },
        classes() {
            let classes = ` text-${this.type}`;
            if(this.context.attributes['checkbox-class'] ) {
                classes += ` ${this.context.attributes['checkbox-class']}`;
            }
            if(this.context.attributes['checkbox-label']) {
                classes += " clickable-label";
            }
            return classes;
        }
    },
    mounted() {
        if (this.context.attributes["checkbox-tooltip"]) {
            this.$nextTick(() => {
                // eslint-disable-next-line no-new
                this.tooltip = new this.$bootstrap.Tooltip(this.$refs["checkbox-wrapper"], {
                    template: "<div class=\"tooltip\" role=\"tooltip\"><div class=\"tooltip-inner\"></div></div>",
                });
            })
        }
    },
    methods: {
        onMouseLeave() {
            if (this.tooltip && this.tooltip.hide) {
                this.tooltip.hide();
            }
        }
    }

}
