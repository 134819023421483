import PersistentStore from 'vuex-persist'


export default ({ store }) => {
    new PersistentStore({
        key: "hcw-crm",
        supportCircular: true,
        reducer: (state) => {
            const appState = {};
            Object.keys(state.app).forEach((key)=>{
                if(!['globalDrag', 'toasts', 'showToast'].includes(key)){
                    appState[key] = state.app[key];
                }
            })
            return {
                app: appState,
                auth: state.auth
            }
        }
    }).plugin(store)

}
