export default function ({$axios, app}) {
    $axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const code = parseInt(error.response && error.response.status);

            if (code === 401) {
                app.$auth.logout();
                app.store.dispatch('app/setUserData', null);
            }

            return Promise.reject(error);
        }
    );
}
