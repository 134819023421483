
export default {
    name: "DatetimeInput",
    props: {
        value: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            required: false,
            default: 300,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            date: null,
            time: null,
            emitTimeout: null,
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.$nextTick(() => {
                    if (newValue) {
                        const [date, time] = newValue.split(' ')
                        this.date = date
                        this.time = time
                    }
                });
            },
            immediate: true,
        }
    },
    methods: {
        updateDate(value) {
            this.date = value;
            this.emitValue();
        },
        updateTime(value) {
            console.log("New time", value);
            this.time = value;
            this.emitValue();
        },
        emitValue() {
            // Wait a bit until emitting data to make sure user finished input
            clearTimeout(this.emitTimeout);
            this.emitTimeout = setTimeout(() => {
                // Combine the date and time values and parse as a date object
                const combinedValue = `${this.date} ${this.time}`;
                const datetime = new Date(combinedValue);

                // Check if the datetime is valid
                if (isNaN(datetime.getTime()) || !this.date || !this.time) {
                    this.$emit('input', '');
                } else {
                    this.$emit('input', combinedValue);
                }
            }, 500);
        },
    },
}
