


// import '@toast-ui/editor/dist/toastui-editor.css';

import CKEditor from 'ckeditor4-vue';

export default {
    name: "FormulateInputEditor",
    components: {
        ckeditor: CKEditor.component,
    },
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    computed: {
        editorConfig() {

            let extraPlugins = "autogrow,base64image,justify,basicstyles,colorbutton,font,copyformatting,fixfonthcw,removepinlihcw";

            const config = {
                defaultLanguage: "en",

                // Simplify the dialog windows.
                removeDialogTabs: 'advanced;link:advanced',
                // Breadcrumb not necessary
                // config.removePlugins = 'elementspath';

                // Do not translate umlauts
                entities: false,

                // Enable the autogrow plugin
                autoGrow_minHeight: 500,
                autoGrow_maxHeight: 800,

                // Allow custom CSS, e.g., for styling of OL
                allowedContent: true,
                toolbar: [
                    {
                        name: 'basicstyles',
                        items: [
                            'Bold',
                            'Italic',
                            'Underline',
                            '-',
                            'NumberedList',
                            'BulletedList',
                            '-',
                            'JustifyLeft',
                            'JustifyCenter',
                            'JustifyRight',
                            'JustifyBlock',
                            '-',
                            'Blockquote',
                            '-',
                            'CopyFormatting'
                        ]
                    },
                    {
                        name: 'styles',
                        items: [
                            // 'Styles',
                            'Format',
                            'Font',
                            'FontSize'
                        ]
                    },
                    {
                        name: 'colors',
                        items: [
                            'TextColor',
                            'BGColor'
                        ]
                    },
                ],
            };

            // https://ckeditor.com/cke4/addon/placeholder_select
            // This is not the placeholder plugin: https://ckeditor.com/cke4/addon/placeholder
            if (this.placeholders.length) {
                extraPlugins += ",richcombo,groupplaceholderselecthcw";
                config.group_placeholder_select = {
                    placeholders: this.placeholders,
                    // format: "<span style='color: red; font-size: 16pt; font-family: times, serif'>{%placeholder%}</span>",
                    format: "{%placeholder%}",
                };

                config.toolbar.push({
                    name: 'placeholders',
                    items: [
                        'group_placeholder_select'
                    ],
                });
            }

            config.toolbar.push({
                name: 'links',
                items: [
                    'Link',
                    'Unlink',
                ]
            });

            if (this.context.attributes['editor-tables']) {
                config.toolbar.push({
                    name: 'insert',
                    items: [
                        'Table',
                        'base64image',
                    ]
                });
            } else {
                config.toolbar.push({
                    name: 'insert',
                    items: [
                        'base64image',
                    ]
                });
            }

            config.toolbar.push({
                name: 'clipboard',
                items: [
                    'PasteText',
                    'PasteFromWord',
                    'RemoveFormat',
                    'FixFontHcw',
                ]
            });

            config.toolbar.push({
                name: 'document',
                items: [
                    'Source',
                    'About',
                ]
            });

            config.extraPlugins = extraPlugins;

            config.removeButtons = 'Subscript,Superscript';
            config.font_names = 'Arial/Arial, Helvetica Neue, sans serif; Calibri; Comic Sans MS; Courier New; ' +
                'Georgia; Lucida Sans Unicode; Tahoma; Times New Roman/Times New Roman, serif; Trebuchet MS; Verdana';
            config.fontSize_sizes = '8/8pt; 9/9pt; 10/10pt; 11/11pt; 12/12pt; 14/14pt; 16/16pt; 18/18pt; 20/20pt; ' +
                '22/22pt; 24/24pt; 26/26pt; 28/28pt; 36/36pt; 48/48pt; 72/72pt;';

            // config.contentsCss = "/engine/ckEditorStyle.css";

            // Below is an example how a keyboard shortcut can get added
            /* config.keystrokes =                [
                    [CKEDITOR.CTRL + 121, 'undo']
                ]; */

            return config;
        },
        placeholders() {
            if (this.context.attributes['editor-placeholders'] && Array.isArray(this.context.attributes['editor-placeholders'])) {
                return [{group: 'Contact Placeholders', options: this.context.attributes['editor-placeholders']}];
            }
            return [];
        },
    },
    created() {
        console.warn("CKEditor component created.");
    },
    methods: {
        onEditorReady() {
            this.context.rootEmit("editor-ready")
        },
        onEditorFocus() {
            this.context.rootEmit("editor-focus")
        },
        onEditorBlur() {
            this.context.rootEmit("editor-blur")
        },
        onUpdate(input) {
            this.context.rootEmit("editor-input")
        },
    }
}
