import { render, staticRenderFns } from "./hint-text.vue?vue&type=template&id=115d636b&scoped=true"
import script from "./hint-text.vue?vue&type=script&lang=js"
export * from "./hint-text.vue?vue&type=script&lang=js"
import style0 from "./hint-text.vue?vue&type=style&index=0&id=115d636b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "115d636b",
  null
  
)

export default component.exports