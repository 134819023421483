
import moment from "moment";
import {mapGetters} from "vuex";
import browserDetector from "../mixins/browser-detector";
import currentBreakpoint from "../mixins/currentBreakpoint";
import navbar from "../mixins/navbar";
import LoadingSpinner from "./loading-spinner";


export default {
    name: "SearchBox",
    components: {LoadingSpinner},
    mixins: [browserDetector, currentBreakpoint, navbar],
    data: () => ({
        searchTerm: "",
        dropdown: null,
        resultsLoaded: false,
        resultsLoading: false,
        cancelSource: null,
        requestTimeout: null,
        preventClose: false,
        dropdownKey: moment().unix(),
        loading: false,
        results: [],
    }),
    computed: {
        ...mapGetters({
            globalClick: "app/globalClick",
        }),
        unixTimestamp() {
            return moment().unix();
        },
        resultSum() {
            let sum = 0;
            /* eslint-disable-next-line */
            this.results.forEach(value => {
                sum += value.rows.length;
            })
            return sum;
        },
        addNegativeMarginToSearchResults() {
            return !(this.currentBreakpoint === 'xxl' || this.isNavbarOpen);
        }
    },

    watch: {
        searchTerm() {
            this.loadSearchResults(this.searchTerm);
        },
        globalClick() {
            if (this.preventClose) {
                this.preventClose = false;
                return;
            }
            this.reset();
        },
    },
    mounted() {
        // Prepare result display
        this.reset()
    },
    methods: {
        loadSearchResults(term) {
            this.resultsLoaded = false;

            clearTimeout(this.requestTimeout);

            if (this.cancelSource) {
                this.cancelSource.cancel('Operation canceled by the user.');
                this.cancelSource = null
            }

            this.requestTimeout = setTimeout(() => {

                if (term.trim() !== "") {

                    this.cancelSource = this.$axios.CancelToken.source();
                    const queryParams = {
                        term,
                        limitPerCategory: 5,
                    };

                    this.resultsLoading = true;

                    // Load results
                    this.$axios.get("/api/search", {
                        params: queryParams,
                        cancelToken: this.cancelSource.token,
                    })
                        .then((response) => {
                            this.results = response.data;
                            this.dropdown.show();
                            this.resultsLoading = false;
                        }).catch((thrown) => {
                            if (this.$axios.isCancel(thrown)) {
                                console.log('Request canceled', thrown.message);
                            } else {
                                console.error(thrown);
                            }
                        });
                } else {
                    this.results = [];
                    this.dropdown.hide();
                }

            }, 250);

        },

        onSearchMouseDown() {
            if (!this.isFirefox()) {
                this.preventClose = true
            }
        },
        onSearchMouseUp() {
            if (!this.isFirefox()) {
                this.$nextTick(() => {
                    this.preventClose = false
                });
            }
        },
        onSearchClick() {
            if (this.isFirefox()) {
                this.preventClose = true;
                this.$nextTick(() => {
                    this.preventClose = false
                });
            }
        },
        reset() {
            this.searchTerm = "";
            this.results = [];
            this.dropdown = null;
            this.resultsLoaded = false;
            this.resultsLoading = false;
            this.cancelSource = null;
            this.requestTimeout = null;
            this.preventClose = false;
            this.dropdownKey = moment().unix();

            this.$nextTick(() => {
                const myDropdownEl = this.$refs.searchBoxTrigger;
                this.dropdown = new this.$bootstrap.Dropdown(myDropdownEl);
            })
        },
        routeToResult(result, baseLink) {
            this.$router.push(`${baseLink}${result.id}`);
            this.reset();
        }
    }
}
