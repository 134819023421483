import {mapGetters} from "vuex";

export default {
    data() {
        return {
            tourCallbacks: {
                onFinish: this.onTourFinish,
                onStop: this.tourStopped,

            },
            preventTourStopping: false
        }
    },
    computed: {
        ...mapGetters("app", ["hiddenCards"]),
        tourSteps() {
            const commonSteps = [
                {
                    target: '#mainNav',
                    header: {
                        title: 'Tour',
                    },
                    content: `This tour will give you an overview of the CRM system. Your can navigate by clicking on the buttons below or by using the arrow keys on your keyboard.`,
                    // params:  {
                    //     type: 'sticky'
                    // }
                },
                {
                    target: '#mainNav',
                    header: {
                        title: 'Navigation bar',
                    },
                    content: `This is the navigation bar with two levels, the upper line shows the modules and core functions, the lower line shows either recent pages or tabs for the active module.`
                },
                {
                    target: '#homeLogo',
                    header: {
                        title: 'Dashboard / Home Screen',
                    },
                    content: `Clicking on the logo brings you back to your home screen.`
                },
                {
                    target: '#navCompanies',
                    header: {
                        title: 'Companies',
                    },
                    content: `Search and filter through the database of all companies in the system.`
                },
                {
                    target: '#navContacts',
                    header: {
                        title: 'Contacts',
                    },
                    content: `Search and filter through the database of all contacts in the system.`
                },
                {
                    target: '#navTransactions',
                    header: {
                        title: 'Deals',
                    },
                    content: `Visit this page to manage Deals.`
                },
                {
                    target: '#navProjects',
                    header: {
                        title: 'Institutional Engagement',
                    },
                    content: `On this page you can manage events and call logs.`
                },
                {
                    target: '#navInteractions',
                    header: {
                        title: 'Interactions',
                    },
                    content: `Here you can track interactions with your contacts.`
                },
                {
                    target: '#navNotes',
                    header: {
                        title: 'Notes',
                    },
                    content: `View and edit all your notes.`
                },
                {
                    target: '#navEmails',
                    header: {
                        title: 'Email Marketing',
                    },
                    content: `Send email campaigns, prepare email drafts and create recipient groups.`
                },

                {
                    target: '#navResearchReports',
                    header: {
                        title: 'Research Reports',
                    },
                    content: `On this page you can check Research Reports.`
                },
                {
                    target: '#navSearch',
                    header: {
                        title: 'Quick Search',
                    },
                    content: `Quickly search for companies, contacts, emails, and notes.`
                },
                {
                    target: '#navNotifications',
                    header: {
                        title: 'Notifications',
                    },
                    content: `Access your notifications here.`
                },
                {
                    target: '#addFavoritePage',
                    header: {
                        title: "Add bookmark"
                    },
                    content: "Click on this button to add a bookmark for the current page.",
                },
                {
                    target: '#history-dropdown',
                    header: {
                        title: "Recently visited contacts and companies."
                    },
                    content: "Click here to easily go back to recently visited contact and company profiles",
                },
            ];

            if (this.userInfo && this.userInfo.isAdminUser) {
                commonSteps.push({
                    target: '#navSettings',
                    header: {
                        title: 'Settings',
                    },
                    content: `Edit system settings.`
                })
            }

            commonSteps.push({
                target: '#navProfile',
                header: {
                    title: 'Profile',
                },
                content: `Click here to edit your profile.`
            });

            let homeSteps = [];
            if (this.currentRoute === "/") {
                homeSteps = [
                    {
                        target: '#abcList .h4',
                        header: {
                            title: "ABC List",
                        },
                        content: "Your ABC list. Use the filters to get specific results.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'right' : 'auto',
                        },
                        offset: -200,
                        before: type => new Promise((resolve, reject) => {
                            if (this.currentBreakpoint !== "xxl") {
                                const collapseNodes = document.querySelectorAll(".navbar-collapse");
                                for (let i = 0; i < collapseNodes.length; i++) {
                                    const collapse = new this.$bootstrap.Collapse(collapseNodes[i], {
                                        toggle: false
                                    });
                                    collapse.hide();
                                }
                            }
                            resolve();
                        })
                    },
                    // {
                    //     target: '#othersAbcList .h4',
                    //     header: {
                    //         title: "Companies of Others"
                    //     },
                    //     content: "View the ABC list of your colleagues by entering their names here.",
                    //     params: {
                    //         placement: 'right',
                    //     },
                    //     offset: -200,
                    // },
                    {
                        target: '#upcomingInteractions h2',
                        header: {
                            title: 'Upcoming Interactions',
                        },
                        content: 'Find your upcoming interactions here.',
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'right' : 'auto',
                        },
                        offset: -200,
                    },
                    {
                        target: '#indexNotes h2',
                        header: {
                            title: "Notes"
                        },
                        content: "Here you can find all your notes.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'right' : 'auto',
                        },
                        offset: -200,
                    },
                    {
                        target: '#recentlyMetContacts h2',
                        header: {
                            title: "Recently met contacts",
                        },
                        content: "Here are all recently met contacts listed.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'right' : 'auto',
                        },
                        offset: -200,
                    },
                ];

                if (!this.hiddenCards.indexFavoritePages) {
                    homeSteps.push({
                        target: '#indexFavoritePages h2',
                        header: {
                            title: "Bookmarks"
                        },
                        content: "Here you can find all your bookmarks.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'left' : 'auto',
                        },
                        offset: -200,
                    });
                }

                const additionalHomeSteps = [{
                    target: '#indexPressReleases h2',
                    header: {
                        title: "Press Releases"
                    },
                    content: "Press Releases of companies on your ABC list are displayed here.",
                    params: {
                        placement: this.currentBreakpoint === "xxl" ? 'left' : 'auto',
                    },
                    offset: -200,
                },
                    {
                        target: '#indexMediaCoverage h2',
                        header: {
                            title: "Media Coverage"
                        },
                        content: "Similar to press releases, media coverage of companies on your ABC list is listed here.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'left' : 'auto',
                        },
                        offset: -200,
                    },
                    {
                        target: '#indexRecentDeals .h4',
                        header: {
                            title: "Recent Deals"
                        },
                        content: "Recent deals of companies on your ABC list.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'left' : 'auto',
                        },
                        offset: -200,
                    },
                    {
                        target: '#indexNotifications .h4',
                        header: {
                            title: "Notifications"
                        },
                        content: "All your recent notifications are listed here.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'left' : 'auto',
                        },
                        offset: -200,
                    }
                ];

                homeSteps = homeSteps.concat(additionalHomeSteps);

                if (!this.hiddenCards.indexFavoriteContacts) {
                    homeSteps.push({
                        target: '#indexFavoriteContacts .h4',
                        header: {
                            title: "Favorite Contacts"
                        },
                        content: "On a contact profile, you can mark contacts with a star. These contacts are listed here.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'left' : 'auto',
                        },
                        offset: -200,
                    })
                }


                homeSteps.push(
                    {
                        target: '#tourStart',
                        header: {
                            title: "Restart Tour",
                        },
                        content: "You can always restart the tour here.",
                        params: {
                            placement: this.currentBreakpoint === "xxl" ? 'left' : 'auto',
                        },
                        offset: -200,
                        before: type => new Promise((resolve, reject) => {
                            const el = document.getElementById("navUserSettingsDropdown");

                            setTimeout(() => {
                                el.click()
                                resolve('foo')
                            }, 250);

                        })
                    });


            }

            return [...commonSteps, ...homeSteps]

        }
    },
    methods: {
        onTourFinish() {
            document.getElementById("navUserSettingsDropdown").click();
            this.$axios.post('/api/user/tour_finished', {
                tourFinished: true
            });
        },
        tourStopped() {
            this.$axios.post('/api/user/tour_finished', {
                tourFinished: true
            });
            this.preventTourStopping = false;
        }
    }
}
