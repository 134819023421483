
export default {
    name: "FormulateInputAutocomplete",
    props: {
        context: {
            type: Object,
            required: true
        },
    }
}
