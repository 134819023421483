
export default {
    name: "LoadingSpinner",
    props: {
        size: {
            type: String,
            default: "1x"
        },
        inline: {
            type: Boolean,
            default: false
        },
        vertical: {
            type:String,
            default:"middle",
            validator: function validator(value) {
                return ["middle","baseline","bottom","top","text-bottom"].includes(value);
            }
        },
        color:{
            type:String,
            default: "dark"
        },
        align:{
            type:String,
            default:"center",
            validator: function validator(value) {
                return ["center","start","end"].includes(value);
            }
        }
    }
}
