import moment from "moment"
import "moment-timezone"
import {mapActions, mapGetters} from "vuex";

import {v4 as uuid} from "uuid";
import replaceAll from "string.prototype.replaceall";


export default {
    computed: {
        ...mapGetters({
            userInfo: 'app/userData'
        }),
        isSuperAdminUser() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_SUPER_ADMIN");
        },
        isAdminUser() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_ADMIN");
        },
        isUserWithPermissionStaffingMgmt() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_STAFFING_MGMT");
        },
        isUserWithPermissionMarketListMgmt() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_MARKET_LIST_MGMT");
        },
        isUserWithPermissionChangelog() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_CHANGELOG");
        },
        isUserWithPermissionRestrictedList() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_RESTRICTED_LIST");
        },
        isUserWithRoleBanker() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_BANKER");
        },
        isUserWithRoleResearchAnalyst() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_RESEARCH_ANALYST");
        },
        isUserWithRoleCorporateAccess() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_CORPORATE_ACCESS");
        },
        isUserWithRoleCompliance() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_DEPT_COMPLIANCE");
        },
        isUserWithPermissionStaffingCheck() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_STAFFING_CHECK");
        },
        isUserWithPermissionAdminPanel() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_ADMIN_PANEL");
        },
        isUserWithPermissionClientMgmt() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_CLIENT_MANAGEMENT");
        },
        isUserWithPermissionDocusign() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_DOCUSIGN");
        },
        isUserWithPermissionThirdPartyMeetingImport() {
            return this.userInfo && this.userInfo.roles
                && this.userInfo.roles.includes("ROLE_PERMISSION_THIRD_PARTY_MEETING_IMPORT");
        },
        maxUploadFileSize() {
            return this.userInfo ? this.userInfo.file_upload_size : 0
        },
    },
    methods: {
        ...mapActions({
            addToast: "app/addToast",
            toggleToast: "app/toggleToast"
        }),
        isEmailAddress(str) {
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
        },
        generateUUID() {
            return uuid();
        },
        buildQueryString(params) {
            const esc = encodeURIComponent;
            return Object.keys(params)
                .map(k => esc(k) + '=' + esc(params[k]))
                .join('&');
        },
        padWithZeros(number, length) {
            let myString = '' + number;
            while (myString.length < length) {
                myString = '0' + myString;
            }
            return myString;
        },
        /**
         * Formats a number by moving the comma and adding an abbreviation (K, M, B, ...)
         * @param num The number to format.
         * @param maxFractionDigits The maximum number of digits after the decimal point.
         * @param keepNonSignificantZeros Whether to keep non-significant zeros after the decimal point.
         * @param maxSignificantDigits The maximum number of significant digits to use.
         * @param symbol The symbol to use instead of a symbol from the lookup table.
         * @returns {string}
         */
        nFormatter(num, maxFractionDigits = 2, keepNonSignificantZeros = false, maxSignificantDigits = 21, symbol = null) {
            if (num === 0) {
                return "0";
            }

            const lookup = [
                {value: 0, symbol: ""},
                {value: 1e3, symbol: "K"}, // Thousand
                {value: 1e6, symbol: "M"}, // Million
                {value: 1e9, symbol: "B"}, // Billion
                {value: 1e12, symbol: "T"}, // Trillion
                {value: 1e15, symbol: "P"}, // Quadrillion
                {value: 1e18, symbol: "E"} // Quintillion
            ];

            let item = null;
            if (!symbol) {
                // Finds the first pair of value and symbol where the value is (slightly) larger than the value to format.
                item = lookup.slice().reverse().find(function (item) {
                    if (item.value === 0)
                        return true;
                    else
                        return Math.abs(num) >= item.value;
                });

                if (!item) {
                    console.error(`Error formatting number ${num}!`);
                    return "0";
                }
            } else {
                // Find the correct item in the lookup table.
                for (const x of lookup) {
                    if (x.symbol === symbol) {
                        item = x;
                        break;
                    }
                }

                if (!item) {
                    console.error(`Cannot find symbol ${symbol} in lookup table!`);
                    return "0";
                }
            }

            const formattingOptions = {
                maximumFractionDigits: maxFractionDigits,
                useGrouping: true, // Whether to use thousands separators.
            };

            // Only include the maximum significant digits option if necessary, because it overwrites
            // the maximum fraction digits option.
            if (maxSignificantDigits >= 1 && maxSignificantDigits <= 20) {
                formattingOptions.maximumSignificantDigits = maxSignificantDigits;
            }

            if (keepNonSignificantZeros) {
                formattingOptions.minimumFractionDigits = maxFractionDigits;
            }

            // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            // return (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol;
            if (item.value === 0) {
                return parseFloat(num).toLocaleString('en-US', formattingOptions);
            } else {
                return (num / item.value).toLocaleString('en-US', formattingOptions) + item.symbol;
            }
        },
        formatDate(dateString, format = 'MM/DD/YYYY') {
            if (!dateString) {
                console.error("DateString is missing");
                return "";
            }

            const date = moment(dateString);
            return date.format(format)
            // const date = new Date(dateString);
            // const day = ("0" + date.getDate()).slice(-2);
            // const month = ("0" + (date.getMonth() + 1)).slice(-2);
            // const year = date.getFullYear();
            // return month + "/" + day + "/" + year;
        },
        /**
         *
         * @param dateString<String> parsable date string
         * @param  timezone<String> null for server timezone, false for none
         * @param  format<String> moment compatible format
         * @returns String
         */
        formatDateTime(dateString, timezone = null, format = 'MM/DD/YYYY hh:mm A zz') {

            if (!dateString) {
                console.error("DateString is missing");
                return "";
            }

            if (timezone === null) {
                timezone = this.userInfo ? this.userInfo.server_timezone : null;
            } else if (timezone === false && format === 'MM/DD/YYYY hh:mm A zz') {
                format = 'MM/DD/YYYY hh:mm A'
            }

            if (timezone) {
                return moment.tz(dateString, timezone).format(format)
            }
            return moment(dateString).format(format)
        },
        formatCurrency(price, currency) {
            try {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency
                });
                return formatter.format(price);
            } catch (e) {
                const parsedFloat = parseFloat(price);
                if (!isNaN(parsedFloat)) {
                    return parsedFloat.toFixed(2);
                } else {
                    return price;
                }
            }
        },
        getCurrencySymbol(currency) {
            try {
                return (0).toLocaleString(
                    'en-US',
                    {
                        style: 'currency',
                        currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }
                ).replace(/\d/g, '').trim()
            } catch (e) {
                return "";
            }
        },
        nFormatCurrency(currency, num, maxFractionDigits = 2, keepNonSignificantZeros = false, maxSignificantDigits = 21, symbol = null) {
            const currencySymbol = this.getCurrencySymbol(currency);
            if (currencySymbol.length > 1) {
                return currencySymbol + " " + this.nFormatter(num, maxFractionDigits, keepNonSignificantZeros, maxSignificantDigits, symbol);
            } else {
                return currencySymbol + this.nFormatter(num, maxFractionDigits, keepNonSignificantZeros, maxSignificantDigits, symbol);
            }
        },
        calculateRatingBadgeColorClass(rating) {
            if (rating) {
                rating = rating.trim().toLowerCase();
            }
            switch (rating) {
                case "buy":
                    return "bg-success";
                case "sell":
                    return "bg-danger";
                default:
                    return "bg-secondary";
            }
        },
        createSimpleHash(s) {
            /* Simple hash function. */
            let a = 1;
            let c = 0;
            let h;
            let o;
            if (s) {
                a = 0;
                for (h = s.length - 1; h >= 0; h--) {
                    o = s.charCodeAt(h);
                    a = (a << 6 & 268435455) + o + (o << 14);
                    c = a & 266338304;
                    a = c !== 0 ? a ^ c >> 21 : a;
                }
            }
            return String(a);
        },
        /**
         * Javascript escaping of text - conferts smaller,larger,and,quotes to HTML tokens
         * @param {type} text
         * @returns {string}
         */
        escapeHtml(text) {
            const map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;'
            };

            if (typeof text === 'string' || text instanceof String) {
                return text.replace(/[&<>"']/g, function (m) {
                    return map[m];
                });
            } else {
                // console.log("Cannot escape [" + text + "], typeof is " + (typeof text));
                return text;
            }
        },
        /**
         * Formats array with strings to string, includes Oxford comma
         * @param arr
         * @param conjunction
         * @returns {string}
         */
        formatArray(arr, conjunction = "and") {
            switch (arr.length) {
                case 0:
                    return "";

                case 1:
                    // Just return the element
                    return arr.find(Boolean);

                case 2:
                    // Joins both with conjunction and no comma
                    // Example: "bob and sam"
                    return arr.join(` ${conjunction} `);

                default:
                    // Joins all with commas and last one with oxford comma and conjunction
                    // Example: "bob, joe, and sam"
                    return `${arr.slice(0, -1).join(', ')}, ${conjunction} ${arr.slice(-1)}`;
            }
        },
        pluralize(number, singular, plural = null, specialForZero = null, includeNumber = true) {
            if (number === 0 && specialForZero !== null) {
                if (plural !== null) {
                    return `${specialForZero} ${plural}`;
                } else {
                    return `${specialForZero} ${singular}s`;
                }
            }

            if (includeNumber) {
                if (number === 1) {
                    return `1 ${singular}`;
                } else if (plural !== null) {
                    return `${number} ${plural}`;
                } else {
                    return `${number} ${singular}s`;
                }
            }

            if (number === 1) {
                return singular;
            } else if (plural !== null) {
                return plural;
            } else {
                return `${singular}s`;
            }
        },
        capitalizeFirstLetter(string) {
            if (!string) {
                return "";
            }

            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        calculateTitle(title = "") {
            if (title === "") return "HCW BTS";
            else return title + " - HCW BTS";
        },
        base64UrlEncodeJahrUtils(value) {
            const encoded = btoa(value);
            const a = replaceAll(encoded, '+', '-');
            return replaceAll(a, '/', '_');
        },
        base64UrlDecodeJahrUtils(value) {
            const d = replaceAll(value, '_', '/');
            const e = replaceAll(d, '-', '+');
            return atob(e);
        },

        copyTextToClipboard(text, toastMessageSuccess = "Copying to clipboard was successful", toastMessageError = "Copying to clipboard was not successful.") {

            return new Promise((resolve, reject) => {

                if (!navigator.clipboard) {
                    const textArea = document.createElement("textarea");
                    textArea.value = text;

                    // Avoid scrolling to bottom
                    textArea.style.top = "0";
                    textArea.style.left = "0";
                    textArea.style.position = "fixed";

                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();

                    let error = false;

                    try {
                        error = !document.execCommand('copy');

                        if (toastMessageSuccess && !error) {
                            const toastId = this.generateUUID();
                            this.addToast({
                                type: "success",
                                title: "Copying",
                                message: toastMessageSuccess,
                                id: toastId,
                            });
                            this.$nextTick(() => {
                                this.toggleToast(toastId);
                            })
                        } else if (!error) {
                            resolve("Successful, fallback");
                        } else {
                            reject(new Error("Unknown error"));
                        }

                    } catch (err) {
                        error = true;
                        reject(err);
                    }
                    if (error && toastMessageError) {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "danger",
                            title: "Copying",
                            message: toastMessageError,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })
                    }
                    document.body.removeChild(textArea);
                } else {
                    navigator.clipboard.writeText(text)
                        .then(() => {
                            if (toastMessageSuccess) {
                                const toastId = this.generateUUID();
                                this.addToast({
                                    type: "success",
                                    title: "Copying",
                                    message: toastMessageSuccess,
                                    id: toastId,
                                });
                                this.$nextTick(() => {
                                    this.toggleToast(toastId);
                                })
                            }
                            resolve("Successful, navigator");

                        })
                        .catch(err => {
                            if (toastMessageError) {
                                const toastId = this.generateUUID();
                                this.addToast({
                                    type: "danger",
                                    title: "Copying",
                                    message: toastMessageError,
                                    id: toastId,
                                });
                                this.$nextTick(() => {
                                    this.toggleToast(toastId);
                                })
                            }
                            reject(err);
                        });
                }

            })

        },
        extractNumericId(source) {
            let idString;

            if (typeof source === 'object') {
                idString = source['@id'];
            } else {
                idString = source;
            }

            return Number(idString.match(/\d+/)[0]);
        },
    }
}
