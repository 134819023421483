import { render, staticRenderFns } from "./edit-favorite-page-modal.vue?vue&type=template&id=33ef7108&scoped=true"
import script from "./edit-favorite-page-modal.vue?vue&type=script&lang=js"
export * from "./edit-favorite-page-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ef7108",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src214552897/src/web/components/save-button.vue').default})
