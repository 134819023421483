
export default {
    name: "FormulateInputTextarea",
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    computed: {
        rows() {
            return this.context.attributes['textarea-rows'] ? this.context.attributes['textarea-rows'] : 10
        },
        placeholder() {
            return this.context.attributes['textarea-placeholder'] ? this.context.attributes['textarea-placeholder'] : null
        },
    }
}
