export default ({app, store, route, $auth}) => {


    $auth.onRedirect((to, from) => {
        // console.error(to)
        // you can optionally change `to` by returning a new value
    })

    app.router.afterEach((to, from) => {
        app.store.dispatch('app/setCurrentRoute', to.path);
    })

    // Every time the route changes (fired on initialization too)
    app.router.beforeEach(async (to, from, next) => {

        if (to.name !== "login" && from.name !== 'login' && to.name !== "logout") {
            await app.store.dispatch('app/setPreviousRoute', to.path);
        } else if (to.name !== "login") {
            await app.store.dispatch('app/setPreviousRoute', "/");
        }

        if (to && to.meta && to.meta.auth === false) {
            console.log("Exception from auth because of meta.auth=false.");
            return next();
        }

        if (to && to.name === "login" || (!to && route && route.name === "login")) {
            console.log("Exception from auth because of login.");
            return next();
        }

        store.dispatch("app/setLoadingIndicator", true);
        store.dispatch("app/setNextRoute", to.path);
        // let loader = this.$loading.show({canCancel: false,});
        try {
            const response = await app.$axios.get("/user_info")

            // eslint-disable-next-line promise/param-names
            // const delay = ms => new Promise(res => setTimeout(res, ms));
            // await delay(5000)

            // Pushing to store => "refreshing"
            store.dispatch("app/setUserData", response.data);

            const routeMeta = to.meta;

            if (
                !response ||
                (routeMeta.superadmin && !response.data.roles.includes("ROLE_SUPER_ADMIN")) ||
                (routeMeta.admin && !response.data.roles.includes("ROLE_ADMIN") && !response.data.roles.includes("ROLE_SUPER_ADMIN")) ||
                (routeMeta.requiredRole && !response.data.roles.includes(routeMeta.requiredRole))
            ) {
                console.error("Logging you out, you cannot access this page because of your roles!");

                await app.$auth.logout();
                store.dispatch("app/setUserData", null);
                store.dispatch("app/setLoadingIndicator", false);
                return next("/login")

            }
            // Now check if we are still on the login page. If yes, move away to the default route
        } catch (e) {

            if (e.response.status === 401 || e.response.status === 403) {
                console.warn("You are NOT logged in.");
                store.dispatch("app/setUserData", null);

                console.warn("AUTHENTICATE 1");

                // Move to login form
                // await app.$auth.logout();

                console.warn("AUTHENTICATE 2");

                if (to && to.name !== "login") {
                    console.warn("AUTHENTICATE 3a", to);

                    store.dispatch("app/setLoadingIndicator", false);

                    console.warn("AUTHENTICATE 3a", to);

                    return next("/login")
                } else {
                    console.warn("AUTHENTICATE 3b", to);
                }
            } else {
                alert(
                    "An error occurred while trying to navigate to the next page. Please try to open the page again.\n"
                    + "If you keep encountering this error, please contact an administrator.\n"
                    + "\n"
                    + "Technical Information\n"
                    + "Code: " + e.response.code + "\n"
                    + "Message: " + e.response.statusMessage + "\n"
                );
            }
        }

        store.dispatch("app/setLoadingIndicator", false);
        return next();
    })
}
