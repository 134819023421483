
import HintText from "../hint-text.vue";

export default {
    name: "FormulateInputPrettyRadioGroups",
    components: {HintText},
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            model: null,
            uuid: this.generateUUID(),
            collapseShown: false,
        }
    },
    computed: {
        classes() {
            let classes = this.context.attributes['radio-class'];
            if (this.context.attributes['radio-inline']) {
                classes += " d-inline-block align-middle"
            } else {
                classes += " d-block"
            }
            return classes;
        },
        atChange() {
            return this.context.attributes['radio-at-change'] ? this.context.attributes['radio-at-change'] : null;
        },
    },
    watch: {
        model() {
            // eslint-disable-next-line vue/no-mutating-props
            this.context.model = this.model;
        }
    },
    mounted() {
        this.$refs.collapse.addEventListener('show.bs.collapse', this.onCollapseShown);
        this.$refs.collapse.addEventListener('hide.bs.collapse', this.onCollapseHidden);
    },
    beforeDestroy() {
        this.$refs.collapse.removeEventListener('show.bs.collapse', this.onCollapseShown);
        this.$refs.collapse.removeEventListener('hide.bs.collapse', this.onCollapseHidden);
    },
    created() {
        this.model = this.context.model ? this.context.model : false;
    },
    methods: {
        onCollapseShown() {
            this.collapseShown = true;
        },
        onCollapseHidden() {
            this.collapseHidden = false;
        }
    },
}
