
export default {
    name: "HintText",
    props:{
        inline:{
            type:Boolean,
            default: false
        }
    }
}
