// formulate.config.js
import {en} from '@braid/vue-formulate-i18n'

export default {
    plugins: [en],
    locale: 'en',
    rules: {
        foobar: ({value}) => ['foo', 'bar'].includes(value)
    },
    library: {
        "autocomplete": {
            classification: 'formulate-input-autocomplete',
            component: 'FormulateInputAutocomplete',
            slotProps: {
                component: [
                    'select-options',
                    'select-reduce',
                    'select-label',
                    'select-placeholder',
                    'select-clearable',
                    'select-multiple',
                    'select-disabled',
                    'select-searchable',
                ]
            }
        },
        "search-select": {
            classification: 'formulate-input-search-select',
            component: 'FormulateInputSearchSelect',
            slotProps: {
                component: [
                    'select-url',
                    'select-label',
                    'select-selected-label',
                    'select-clearable',
                    'select-multiple',
                    'select-disabled',
                    'select-searchable',
                    'select-add-options',
                    'select-search-on-click',
                    'select-search-on-init',
                    'select-authenticate',
                    'select-placeholder',
                    'select-options-title',
                    'select-params',
                    'select-needed-input',
                    'select-initial-options',
                    'select-keep-initial-options',
                ]
            }
        },
        "editor": {
            classification: 'formulate-input-editor',
            component: 'FormulateInputEditor',
            slotProps: {
                component: [
                    'editor-placeholders',
                    'editor-images',
                    'editor-tables',
                    'editor-disabled',
                ],
            }
        },
        "textarea": {
            classification: 'formulate-input-textarea',
            component: 'FormulateInputTextarea',
            slotProps: {
                component: [
                    'textarea-rows',
                    'textarea-class',
                ],
            }
        },
        "p-check": {
            classification: 'formulate-input-p-check',
            component: 'FormulateInputPrettyCheckbox',
            slotProps: {
                component: [
                    'checkbox-disabled',
                    'checkbox-centered',
                    'checkbox-tooltip',
                    'checkbox-class',
                    'checkbox-label'
                ],
            }
        },
        "p-radio": {
            classification: 'formulate-input-p-radio',
            component: 'FormulateInputPrettyRadios',
            slotProps: {
                component: [
                    'radio-inline',
                    'radio-disabled',
                    'radio-options',
                    'radio-class'
                ],
            }
        },
        "p-radio-grouped": {
            classification: 'formulate-input-p-radio-grouped',
            component: 'FormulateInputPrettyRadioGroups',
            slotProps: {
                component: [
                    'radio-inline',
                    'radio-disabled',
                    'radio-options',
                    'radio-class'
                ],
            }
        },
        "flatpickr": {
            classification: 'formulate-input-flatpickr',
            component: 'FormulateInputFlatpickr',
            slotProps: {
                component: [
                    'flatpickr-config',
                ]
            }
        },
        "datetime": {
            classification: 'formulate-input-datetime',
            component: 'FormulateInputDatetime',
            slotProps: {
                component: [
                    'input-step',
                    'input-disabled',
                ]
            }
        }
    }
}
