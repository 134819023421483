
import DatetimeInput from "../datetime-input.vue";

export default {
    name: "FormulateInputDatetime",
    components: {DatetimeInput},
    props: {
        context: {
            type: Object,
            required: true,
        }
    },
}
