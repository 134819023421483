export default function ({ $axios, store }) {
    $axios.onError(res => {
        if(!store.getters['app/preventErrorHandling']){
            store.dispatch("app/setCurrentError", res);
        }
        store.dispatch('app/setPreventErrorHandling',false);
    })

    $axios.onResponse((res)=>{
        store.dispatch('app/setPreventErrorHandling',false);
    })
}
