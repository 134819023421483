
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
// import Navbar from "../components/navbar";
import * as Sentry from "@sentry/vue";
import Toast from "../components/toast";
import modals from "../mixins/modals";
import homeTour from "../mixins/homeTour";
import NotificationsModal from "../components/notifications-modal";
import EditFavoritePageModal from "../components/edit-favorite-page-modal";
import GiveFeedbackModal from "../components/give-feedback-modal";
import Navbar2 from "../components/navbar2.vue";
import PageFooter from "~/components/footer";


export default {
    name: "Default",
    components: {
        Navbar2,
        GiveFeedbackModal,
        NotificationsModal,
        Toast, /* Navbar, */
        PageFooter,
        EditFavoritePageModal
    },
    mixins: [modals, homeTour],
    data() {
        return {
            dragCounter: 0,
            errorTitle: null,
            errorBody: null,
            notificationsModal: null,
            smallestNotificationId: Number.MAX_SAFE_INTEGER,
            highestNotificationId: 0,
            pages: {},
            savePageModal: null,
            savePageKey: this.generateUUID(),
            giveFeedbackModal: null,
            giveFeedbackKey: this.generateUUID(),
        }
    },
    computed: {
        ...mapGetters({
            loadingIndicator: 'app/loadingIndicator',
            toasts: 'app/toasts',
            showToast: 'app/showToast',
            currentError: 'app/currentError',
            globalDrag: 'app/globalDrag',
            currentRoute: 'app/currentRoute',
            currentCompany: 'app/currentCompany',
            companyHistory: "app/companyHistory",
            warnNotSaved: "app/warnNotSaved",
            userData: "app/userData",
        }),
        shrinked() {
            return !!this.$route.meta.shrinked
        },
        showSubmenu() {
            return true;
            // return this.$route.meta.submenu !== 'company' && this.$route.meta.submenu !== 'companyHistory' ||
            //     (
            //         this.$route.meta.submenu === 'company' && this.currentCompany !== false ||
            //         this.$route.meta.submenu === 'companyHistory' && this.companyHistory.length > 0
            //     )
        },
        legacyNav() {
            return localStorage.getItem("legacyNav") === "yes";
        }
    },
    watch: {
        loadingIndicator(bool) {
            if (bool) {
                this.$root.$loading.start();
            } else {
                this.$root.$loading.finish();
            }
        },
        $route() {

            // console.log(to, from);
            // document.getElementById("toggle1").checked = false;
        },
        currentError(res) {
            let addReportingPrefix = true;
            if (res && res.__CANCEL__ !== true) {
                if (res.request && res.request.status === 401) {
                    return;
                }
                if (res.request && res.request.status === 403) {
                    this.errorTitle = "You are not authenticated";
                    this.errorBody = "[403] You are not permitted to access this resource";
                } else if (res.request && res.request.status === 0 && res.message === 'Request aborted') {
                    // Request has been aborted, probably intentionally. Do not show an error messsage
                    return;
                } else if (res.request && res.request.status === 0) {
                    this.errorTitle = "Internal Server Error";
                    this.errorBody = "[0] The connection to the server could not be established.";

                } else if (res.request && res.request.status === 404) {
                    addReportingPrefix = false;

                    this.errorTitle = "Not Found";
                    this.errorBody = "Error with request: " + res.request.status + " " + res.request.statusText;

                } else if (res.request && res.request.status === 412) { // HTTP_PRECONDITION_FAILED
                    addReportingPrefix = false;

                    this.errorTitle = "Cannot Delete Object";
                    this.errorBody = "Deleting not possible because object is linked to other database objects: " +
                        res.request.status +
                        " " +
                        res.request.statusText;

                } else if (res.request && res.request.status === 422) { // HTTP_UNPROCESSABLE_ENTITY
                    addReportingPrefix = false;
                    this.errorTitle = res.request.status + ": " + res.request.statusText;
                    const responseData = JSON.parse(res.request.response);
                    if (responseData && responseData["hydra:description"]) {
                        this.errorBody = responseData["hydra:description"];
                    } else {
                        this.errorBody = "Cannot process update";
                    }
                } else if (res.request && res.request.status === 500) {
                    this.errorTitle = res.request.status + ": " + (res.request.statusText ? res.request.statusText : "Internal Server Error");
                    let responseData = null;
                    try {
                        responseData = JSON.parse(res.request.response);
                    } catch (e) {

                    }
                    if (responseData && responseData["hydra:description"]) {
                        addReportingPrefix = false;
                        this.errorBody = responseData["hydra:description"];
                    } else {
                        addReportingPrefix = true;
                    }
                } else if (res.request && res.request.status !== 200) {
                    console.warn("Other error with request", res.request);
                    const status = res.request.status ?? "N/A";
                    const statusText = res.request.statusText ?? "N/A";
                    this.errorTitle = "Other Error";
                    this.errorBody = "Error with request: " + status + " " + statusText;
                } else {
                    this.errorTitle = "Unknown Error";
                    this.errorBody = "Error with request: " + res.request.status + " " + res.request.statusText;
                }

                // Report error to sentry
                if (this.userInfo && this.userInfo.hasOwnProperty('id') && this.userInfo.id > 0) {
                    Sentry.setUser({
                        id: this.userInfo.id
                    });
                }
                this.$sentry.captureException(res);

                const toastId = this.generateUUID();
                this.addToast({
                    type: "danger",
                    title: this.errorTitle,
                    message: (addReportingPrefix ? "An error occurred, it has been reported and will be fixed soon: " : "") + this.errorBody,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });

            } else {
                // no "res" or request was cancelled
                this.errorBody = null;
                this.errorTitle = null;
            }
        },
        currentRoute() {
            this.checkTourRoute();
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.onPageUnload);
    },
    created() {
        this.setWarnNotSaved(false)
        // eslint-disable-next-line nuxt/no-globals-in-created
        window.addEventListener('beforeunload', this.onPageUnload);
    },
    mounted() {
        this.checkTourRoute();
        this.loadPages();
    },
    methods: {
        ...mapActions({
            setGlobalDrag: 'app/setGlobalDrag',
            setCurrentError: 'app/setCurrentError',
            setUserData: "app/setUserData",
            setGlobalClick: "app/setGlobalClick",
            setWarnNotSaved: "app/setWarnNotSaved",
            setNumberNotifications: "app/setNumberNotifications",
        }),
        checkTourRoute() {
            if (
                this.userInfo && !this.userInfo.tour_finished &&
                this.currentRoute === '/' &&
                this.$tours.crmTour
            ) {
                this.$tours.crmTour.start();
            } else if (this.$tours.crmTour && this.$tours.crmTour.isRunning && this.currentRoute !== '/') {
                this.$tours.crmTour.skip();
            }
        },

        giveFeedback() {
            this.giveFeedbackModal = this.openModal(this.$refs.giveFeedbackModal);
        },
        closeFeedbackModal() {
            this.closeModal(this.giveFeedbackModal);
            this.giveFeedbackKey = moment().unix();
        },
        onErrorClosed() {
            this.setCurrentError(null)

            // fix bootstrap fail, setting overflow and padding on body element but not removing it
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
        },
        addDrag() {
            this.dragCounter++;
            this.setGlobalDrag(true);
        },
        leaveDrag() {
            this.dragCounter--;
            if (this.dragCounter <= 0) {
                this.setGlobalDrag(false);
            }
        },
        drop(e) {
            e.preventDefault();
            this.setGlobalDrag(false)
        },
        onGlobalClick(e) {
            this.setGlobalClick(moment().valueOf());
            this.setGlobalDrag(false)
        },
        toastClickHandler(toast) {
            if (toast.onClicked && typeof toast.onClicked === 'function') {
                toast.onClicked();
            }
        },
        onPageUnload(event) {
            if (this.warnNotSaved) {
                event.returnValue = `Are you sure you want to leave? Changes will get lost.`;
                return event.returnValue;
            }
        },
        showNotifications() {
            this.modal = this.openModal(this.$refs.notificationsModal);
        },
        updateNumberNotifications() {
            this.$axios.post("/api/users/mark_notifications_as_read", {
                smallestId: this.smallestNotificationId,
                highestId: this.highestNotificationId,
            }).then(response => {
                this.setNumberNotifications(response.data);
            }).finally(() => {
                this.smallestNotificationId = Number.MAX_SAFE_INTEGER;
                this.highestNotificationId = 0;
            });
        },
        updateHighestNotificationId(smallestId, highestId) {
            this.smallestNotificationId = Math.min(this.smallestNotificationId, smallestId);
            this.highestNotificationId = Math.max(this.highestNotificationId, highestId);
        },
        loadPages() {
            this.$axios.get("/api/get_favorite_pages").then(response => {
                this.pages = response.data;

            }).finally(() => {
            })
        },
        savePage() {
            this.savePageModal = this.openModal(this.$refs.savePageModal);
        },
        onPagesUpdated() {
            if (this.savePageModal) {
                this.closeModal(this.savePageModal);
            }
            this.savePageKey = moment().unix();
            this.loadPages();
        },
    },
}
