
export default {
    name: 'LoadingComponent',
    data: () => ({
        loading: false
    }),
    beforeDestroy() {
        document.documentElement.removeAttribute('state')
    },
    methods: {
        start() {
            this.loading = true
            document.documentElement.setAttribute('state', 'loading')
        },
        finish() {
            this.loading = false
            document.documentElement.removeAttribute('state')
        }
    }
}
