export default {
    data() {
        return {
            breakpoints: {
                xs: 0,
                sm: 576,
                md: 768,
                lg: 992,
                xl: 1200,
                xxl: 1400
            },
            screenWidth: null,
        }
    },
    computed: {
        currentBreakpoint() {
            return this.getBreakpoint(this.screenWidth);
        }
    },
    mounted() {
        this.updateScreenWidth();
        window.addEventListener('resize', this.updateScreenWidth);
    },
    destroyed() {
        window.removeEventListener('resize', this.updateScreenWidth);
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        getBreakpoint(width) {
            if (width < this.breakpoints.sm) {
                return 'xs';
            } else if (width < this.breakpoints.md) {
                return 'sm';
            } else if (width < this.breakpoints.lg) {
                return 'md';
            } else if (width < this.breakpoints.xl) {
                return 'lg';
            } else if (width < this.breakpoints.xxl) {
                return 'xl';
            } else {
                return 'xxl';
            }
        }
    }
}
