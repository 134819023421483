import Vue from "vue";

export const state = () => ({
    previousRoute: null,
    currentRoute: null,
    userData: null,
    loadingIndicator: null,
    currentCompany: null,
    currentContact: null,
    globalDrag: false,
    globalClick: null,
    toasts: [],
    showToast: {},
    currentError: null,
    preventErrorHandling: false,
    warnNotSaved: false,
    companyHistory: [],
    contactHistory: [],
    bulkContactFiltersHash: null,
    bulkInteractionFiltersHash: null,
    bulkResearchReportFiltersHash: null,
    bulkInterestListItemsFiltersHash: null,
    currentEmail: false,
    storedEmail: null,
    hiddenCards: {},
    nextRoute: null,
    companyCache: [],
})

export const mutations = {
    SET_PREVIOUS_ROUTE: (state, route) => {
        state.previousRoute = route
    },
    SET_CURRENT_ROUTE: (state, route) => {
        state.currentRoute = route
    },
    SET_USER_DATA: (state, data) => {
        state.userData = data
    },
    SET_NUMBER_NOTIFICATIONS: (state, data) => {
        state.userData.numberNotifications = data;
    },
    SET_LOADING_INDICATOR: (state, bool) => {
        state.loadingIndicator = bool
    },
    SET_CURRENT_COMPANY: (state, company) => {
        state.currentCompany = company
    },
    SET_CURRENT_CONTACT: (state, contact) => {
        state.currentContact = contact
    },
    SET_GLOBAL_DRAG: (state, val) => {
        state.globalDrag = val
    },
    ADD_TOAST: (state, toast) => {
        state.toasts.push(toast)
    },
    TOGGLE_TOAST: (state, uuid) => {
        Vue.set(state.showToast, uuid, !state.showToast[uuid])
    },
    SET_CURRENT_ERROR: (state, error) => {
        state.currentError = error
    },
    SET_PREVENT_ERROR_HANDLING: (state, bool) => {
        state.preventErrorHandling = bool
    },
    SET_WARN_NOT_SAVED: (state, bool) => {
        state.warnNotSaved = bool
    },
    SET_GLOBAL_CLICK: (state, timestamp) => {
        state.globalClick = timestamp
    },
    ADD_TO_COMPANY_HISTORY: (state, company) => {
        const filtered = state.companyHistory.filter(c => c.id === company.id);
        let updatedHistory = [];
        if (filtered.length > 0) {
            // Company is already in history, move to beginning
            updatedHistory = state.companyHistory.sort(
                (a, b) => a.id === company.id ? -1 : b.id === company.id ? 1 : 0
            );
        } else {
            // Add Company to history
            updatedHistory = [{
                id: company.id,
                name: company.name
            }].concat(state.companyHistory)

            if (updatedHistory.length > 6) {
                updatedHistory.pop();
            }
        }
        state.companyHistory = updatedHistory;
    },
    REMOVE_FROM_COMPANY_HISTORY: (state, companyId) => {
        let updatedHistory = [];
        updatedHistory = state.companyHistory.filter(c => c.id !== companyId);
        state.companyHistory = updatedHistory
    },
    ADD_TO_CONTACT_HISTORY: (state, contact) => {
        const filtered = state.contactHistory.filter(c => c.id === contact.id);
        let updatedHistory = [];
        if (filtered.length > 0) {
            // Contact is already in history, move to beginning
            updatedHistory = state.contactHistory.sort((a, b) => a.id === contact.id ? -1 : b.id === contact.id ? 1 : 0);
        } else {
            // Add Contact to history
            updatedHistory = [{
                id: contact.id,
                name: contact.name,
                email: contact.email
            }].concat(state.contactHistory)

            if (updatedHistory.length > 6) {
                updatedHistory.pop();
            }
        }
        state.contactHistory = updatedHistory;
    },
    REMOVE_FROM_CONTACT_HISTORY: (state, contactId) => {
        let updatedHistory = [];
        updatedHistory = state.contactHistory.filter(c => c.id !== contactId);
        state.contactHistory = updatedHistory
    },
    SET_BULK_CONTACT_FILTERS_HASH: (state, hash) => {
        state.bulkContactFiltersHash = hash
    },
    SET_BULK_INTERACTION_FILTERS_HASH: (state, hash) => {
        state.bulkInteractionFiltersHash = hash
    },
    SET_BULK_RESEARCH_REPORT_FILTERS_HASH: (state, hash) => {
        state.bulkResearchReportFiltersHash = hash
    },
    SET_BULK_INTEREST_LIST_ITEMS_FILTERS_HASH: (state, hash) => {
        state.bulkInterestListItemsFiltersHash = hash
    },
    SET_STORED_EMAIL: (state, email) => {
        state.storedEmail = email
    },
    SET_CURRENT_EMAIL: (state, email) => {
        state.currentEmail = email
    },
    ADD_HIDDEN_CARD: (state, elementId) => {
        Vue.set(state.hiddenCards, elementId, true)
    },
    REMOVE_HIDDEN_CARD: (state, elementId) => {
        Vue.set(state.hiddenCards, elementId, false)
    },
    SET_NEXT_ROUTE: (state, route) => {
        state.nextRoute = route
    },
    ADD_TO_COMPANY_CACHE: (state, company) => {
        const filtered = state.companyCache.filter(c => c.id === company.id);
        let updatedCompanyCache = [];
        if (filtered.length > 0) {
            // Company is already in cache, move to beginning
            updatedCompanyCache = state.companyCache.sort((a, b) => a.id === company.id ? -1 : b.id === company.id ? 1 : 0);
        } else {
            // Add Company to cache
            updatedCompanyCache = [{
                id: company.id,
                name: company.name,
                email: company.email
            }].concat(state.companyCache)

            if (updatedCompanyCache.length > 6) {
                updatedCompanyCache.pop();
            }
        }
        state.companyCache = updatedCompanyCache;
    },

}


export const actions = {
    setPreviousRoute({commit}, route) {
        commit('SET_PREVIOUS_ROUTE', route)
    },
    setCurrentRoute({commit}, route) {
        commit('SET_CURRENT_ROUTE', route)
    },
    setUserData({commit}, data) {
        commit('SET_USER_DATA', data)
    },
    setNumberNotifications({commit}, data) {
        commit('SET_NUMBER_NOTIFICATIONS', data)
    },
    setGlobalDrag({commit}, val) {
        commit('SET_GLOBAL_DRAG', val)
    },
    setLoadingIndicator({commit}, bool) {
        commit('SET_LOADING_INDICATOR', bool)
    },
    setNextRoute({commit}, route) {
        commit('SET_NEXT_ROUTE', route)
    },
    setCurrentCompany({commit}, company) {
        commit('SET_CURRENT_COMPANY', company)
        if (company) {
            commit('ADD_TO_COMPANY_HISTORY', company);
        }
    },
    setCurrentContact({commit}, contact) {
        commit('SET_CURRENT_CONTACT', contact)
        if (contact) {
            commit('ADD_TO_CONTACT_HISTORY', contact);
        }
    },
    addToast({commit}, toast) {
        commit('ADD_TOAST', toast)
    },
    toggleToast({commit}, uuid) {
        commit('TOGGLE_TOAST', uuid)
    },
    setCurrentError({commit}, error) {
        if (error && error.message && error.message === "Operation canceled by the user.") {
            // this is ok
            return;
        }

        // Shorten data of the request if it is too long (could go above store limits, then no error message is displayed)
        if (error.config.data) {
            error.config.data = (error.config.data.length > 128 * 1024)
                ? ("SHORTENED " + error.config.data.substring(0, 128 * 1024) + " SHORTENED")
                : error.config.data;
        }

        commit('SET_CURRENT_ERROR', error)
    },
    setPreventErrorHandling({commit}, bool) {
        commit('SET_PREVENT_ERROR_HANDLING', bool)
    },
    setWarnNotSaved({commit}, bool) {
        commit('SET_WARN_NOT_SAVED', bool)
    },
    setGlobalClick({commit}, timestamp) {
        commit('SET_GLOBAL_CLICK', timestamp)
    },
    setBulkContactFiltersHash({commit}, hash) {
        console.log("Setting bulkContactFiltersHash to", hash);

        commit('SET_BULK_CONTACT_FILTERS_HASH', hash)
        return Promise.resolve();
    },
    setBulkResearchReportFiltersHash({commit}, hash) {
        console.log("Setting BulkResearchReportFiltersHash to", hash);
        commit('SET_BULK_RESEARCH_REPORT_FILTERS_HASH', hash)
        return Promise.resolve();
    },
    setBulkInterestListItemsFiltersHash({commit}, hash) {
        console.log("Setting BulkInterestListItemsFiltersHash to", hash);
        commit('SET_BULK_INTEREST_LIST_ITEMS_FILTERS_HASH', hash)
        return Promise.resolve();
    },
    setBulkInteractionFiltersHash({commit}, hash) {
        commit('SET_BULK_INTERACTION_FILTERS_HASH', hash)
        return Promise.resolve();
    },
    setCurrentEmail({commit}, email) {
        commit('SET_CURRENT_EMAIL', email)
    },
    setStoredEmail({commit}, email) {
        commit('SET_STORED_EMAIL', email)
    },
    setTourFinished({commit}, bool) {
        commit('SET_TOUR_FINISHED', bool);
    },
    removeFromCompanyHistory({commit}, companyId) {
        commit('REMOVE_FROM_COMPANY_HISTORY', companyId);
    },
    removeFromContactHistory({commit}, contactId) {
        commit('REMOVE_FROM_CONTACT_HISTORY', contactId);
    },
    addHiddenCard({commit}, elementId) {
        commit('ADD_HIDDEN_CARD', elementId);
    },
    removeHiddenCard({commit}, elementId) {
        commit('REMOVE_HIDDEN_CARD', elementId);
    },
    addToCompanyCache({commit}, company) {
        commit('ADD_TO_COMPANY_CACHE', company);
    },
}

export const getters = {
    previousRoute: (state) => state.previousRoute,
    currentRoute: (state) => state.currentRoute,
    userData: (state) => state.userData,
    globalDrag: (state) => state.globalDrag,
    loadingIndicator: (state) => state.loadingIndicator,
    currentCompany: (state) => state.currentCompany,
    toasts: (state) => state.toasts,
    showToast: (state) => state.showToast,
    currentError: (state) => state.currentError,
    preventErrorHandling: (state) => state.preventErrorHandling,
    globalClick: (state) => state.globalClick,
    companyHistory: (state) => state.companyHistory,
    contactHistory: (state) => state.contactHistory,
    bulkContactFiltersHash: (state) => state.bulkContactFiltersHash,
    bulkInteractionFiltersHash: (state) => state.bulkInteractionFiltersHash,
    bulkResearchReportFiltersHash: (state) => state.bulkResearchReportFiltersHash,
    bulkInterestListItemsFiltersHash: (state) => state.bulkInterestListItemsFiltersHash,
    currentEmail: (state) => state.currentEmail,
    storedEmail: (state) => state.storedEmail,
    hiddenCards: (state) => state.hiddenCards,
    warnNotSaved: (state) => state.warnNotSaved,
    nextRoute: (state) => state.nextRoute,
    companyCache: (state) => state.companyCache,
}
