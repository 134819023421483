
export default {
    name: "TableFilters",
    props:{
        openedFilter: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            opend: this.openedFilter,
            collapseIdentifier: `collapse-${this.generateUUID()}`,
            collapseShow: this.openedFilter,
            collapseHidden: !this.openedFilter,
        }
    },
    mounted() {
        this.$refs.collapse.addEventListener('show.bs.collapse', () => {
            this.collapseShow = true;
            this.collapseHidden = false;
            this.$emit('show');
        })
        this.$refs.collapse.addEventListener('hide.bs.collapse', () => {
            this.collapseShow = false;
        })
        this.$refs.collapse.addEventListener('hidden.bs.collapse', () => {
            this.collapseHidden = true;
            this.$emit('hidden');
        })
    },
}
