
export default {
    name: "InitialsCircle",
    props: {
        initials: {
            type: String,
            default: "",
        }
    },
    computed: {
        preparedInitials() {
            // Clean up the string
            return this.initials.replace(/[^\w\s]|_|\s+/g, "").substring(0, 5);
        },
        fontSize() {
            // Calculate a size that fits into the circle. Calculation below is safe as preparedInitials is between 0 and 5 chars long
            return 18 - ((this.preparedInitials.length * 3) - 1);
        }
    }
}
